import Vue from 'vue'
import App from './components/App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment-timezone'
//import VueTheMask from 'vue-the-mask'



////Use SignalRHub plugin (Starts connection) {{ENABLE SIGNALR}}
//import PhoneCenterHubPlugin from "./signalr/PhoneCenterHubPlugin"
//Vue.use(PhoneCenterHubPlugin);

Vue.config.productionTip = false


//import SWAL 
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import vuetify from './plugins/vuetify'


//Import moment
moment.tz.setDefault('UTC');
Vue.prototype.$moment = moment;

moment.tz.setDefault('UTC');

//Import VueTheMask 
//Vue.use(VueTheMask)

//Vue Filters
Vue.filter('imgpath', function (value) {

    return "dist/images/news/" + value;
})
Vue.filter('casenum_format', function (value) {
    if (!value) return ''
    value = value.toString();
    return value.substring(0, 2) + '-' + value.substring(4, value.length);
})

Vue.filter('shortDate', function (value) {
    if (!value) return '';
    return Vue.prototype.$moment(value).format("MM/DD/YYYY");
})
//datePicker
Vue.filter('datePicker', function (value) {
    if (!value) return '';

    return Vue.prototype.$moment(value).format("YYYY-MM-DD");
})


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
